import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const cardContainer = classNames({
  'home-press-card-container': true,
})

const pictureClass = classNames({
  'home-press-card-picture': true,
})

class Card extends Component {

  render() {
    const {
      picture,
      title,
      alt,
      url,
    } = this.props

    return (
      <a
        href={url}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={cardContainer}>
          <img
            className={pictureClass}
            src={picture}
            alt={alt}
          />
        </div>
      </a>
    )
  }
}

Card.propTypes = {
  picture: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

Card.defaultProps = {
  picture: undefined,
  title: undefined,
  alt: undefined,
  url: undefined,
}

export default Card
