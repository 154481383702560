import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'

import Space from '../../../components/Space'
import Card from './components/Card'

import usineDigitalePicture from '../../../img/logos/press_usine_digitale.png'
import forbesPicture from '../../../img/logos/press_forbes.svg'
import maddynessPicture from '../../../img/logos/press_maddyness.svg'
import bpiFrancePicture from '../../../img/logos/press_bpifrance.svg'

const USINE_DIGITALE_URL = 'https://www.usine-digitale.fr/article/wuha-le-moteur-de-recherche-qui-permet-aux-entreprises-de-mieux-explorer-leurs-donnees.N513909'
const FORBES_URL = 'https://www.forbes.fr/leaderstalk/wuha-leaders-talk/'
const MADDYNESS_URL = 'https://www.maddyness.com/2017/03/09/maddytools-wuha-ne-plus-perdre-de-temps-a-chercher-information-travail/'
const BPI_FRANCE_URL = 'https://presse.bpifrance.fr/french-tech-tour-america-2018-20-startups-2-semaines-dimmersion-4-destinations/'

const backgroundClass = classNames({
  'home-press-background': true,
})

const containerClass = classNames({
  'home-press-container': true,
})

const titleContainerClass = classNames({
  'home-press-title-container': true,
})

class Press extends Component {

  render() {
    return (
      <div className={backgroundClass}>
        <div className={containerClass}>
          <Container>
            <Space type="xxl" />
            <Row>
              {this.renderHeader()}
            </Row>
            <Space type="xl" />
            <Row>
              {this.renderCards()}
            </Row>
            <Space type="xxl" />
          </Container>
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'home.press.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderCards = () => {
    return (
      <Fragment>
        <Col xs={12} sm={4} md={4} lg={2} offset={{ sm: 2, md: 2 }}>
          <Card
            picture={usineDigitalePicture}
            title="L'usine digitale"
            alt="l'usine digitale"
            url={USINE_DIGITALE_URL}
          />
          <Visible xs sm>
            <Space type="xs" />
          </Visible>
        </Col>

        <Col xs={12} sm={4} md={4} lg={2}>
          <Card
            picture={forbesPicture}
            title="Forbes"
            alt="forbes"
            url={FORBES_URL}
          />
          <Visible xs sm md>
            <Space type="xs" />
          </Visible>
        </Col>

        <Col xs={12} sm={4} md={4} lg={2} offset={{ sm: 2, md: 2, lg: 0 }}>
          <Card
            picture={maddynessPicture}
            title="Maddyness"
            alt="maddyness"
            url={MADDYNESS_URL}
          />
          <Visible xs sm>
            <Space type="xs" />
          </Visible>
        </Col>

        <Col xs={12} sm={4} md={4} lg={2}>
          <Card
            picture={bpiFrancePicture}
            title="Bpifrance"
            alt="bpifrance"
            url={BPI_FRANCE_URL}
          />
        </Col>
      </Fragment>
    )
  }
}

export default injectIntl(Press)
