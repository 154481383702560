import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Button from '../../../components/Button'
import Space from '../../../components/Space'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const backgroundContainerClass = classNames({
  'home-header-background-container': true,
})

const backgroundImageClass = classNames({
  'home-header-background-image': true,
})

const navigationSpace = classNames({
  'home-header-navigation-space': true,
})

const containerClass = classNames({
  'home-header-container': true,
})

const descriptionTextClass = classNames({
  'home-header-description-text': true,
})

class Header extends Component {

  render() {
    return (
      <div className={backgroundContainerClass}>
        <div className={backgroundImageClass} />
        <Container>
          <div className={navigationSpace} />
          <Space type="s" />
          <Row>
            {this.renderLeftPane()}
            {this.renderRightPane()}
          </Row>
        </Container>
      </div>
    )
  }

  renderLeftPane = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={5}>
        <div className={containerClass}>
          <h1>
            {formatMessage({ id: 'home.header.title' })}
          </h1>
          <Space type="m" />
          <p className={descriptionTextClass}>
            {formatMessage({ id: 'home.header.description' })}
          </p>
          <Space type="m" />
          <a href={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=home-header`}>
            <Button
              text={formatMessage({ id: 'home.header.action.connect' })}
              color="red"
              isExpanded={false}
            />
          </a>
          <Visible xs sm md>
            <Space type="m" />
          </Visible>
        </div>
      </Col>
    )
  }

  renderRightPane = () => {
    const {
      data,
    } = this.props

    return (
      <Col sm={12} md={7}>
        <GatsbyImage
          fluid={data.headerPicture.childImageSharp.fluid}
          alt="header illustration"
          width={'100%'}
        />
        <Visible xs sm md>
          <Space type="m" />
        </Visible>
      </Col>
    )
  }
}

export default injectIntl(Header)
