import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible, Hidden, ScreenClassRender } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Image from '../../../components/Image'
import Space from '../../../components/Space'
import Title from './components/Title'
import Description from './components/Description'

import smallArrowRightBlueIcon from '../../../img/icons/icon_small_arrow_right_blue.svg'
import number1Picture from '../../../img/artworks/artwork_big_number_01.svg'
import number2Picture from '../../../img/artworks/artwork_big_number_02.svg'
import number3Picture from '../../../img/artworks/artwork_big_number_03.svg'
import number4Picture from '../../../img/artworks/artwork_big_number_04.svg'
import brush1 from '../../../img/artworks/artwork_home_brush_1.svg'
import brush2 from '../../../img/artworks/artwork_home_brush_2.svg'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const containerClass = classNames({
  'home-install-container': true,
})

const titleContainerClass = classNames({
  'home-install-title-container': true,
})

class Install extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          <Row>
            {this.renderHeader()}
          </Row>
          <Space type="giant" />
          <Row>
            {this.renderSteps()}
          </Row>
        </Container>
      </div>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'home.install.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderSteps = () => {
    const {
      data,
    } = this.props

    return (
      <Fragment>
        <Visible xs sm>
          {this.renderArtwork(data.step1Picture.childImageSharp.fluid, true)}
        </Visible>
        {this.renderInstall()}
        <Hidden xs sm>
          {this.renderArtwork(data.step1Picture.childImageSharp.fluid, true)}
        </Hidden>

        {this.renderBrush(brush1)}

        {this.renderArtwork(data.step2Picture.childImageSharp.fluid)}
        {this.renderConnect(2, true)}

        {this.renderBrush(brush2)}

        <Visible xs sm>
          {this.renderArtwork(data.step3Picture.childImageSharp.fluid, true)}
        </Visible>
        {this.renderSearch()}
        <Hidden xs sm>
          {this.renderArtwork(data.step3Picture.childImageSharp.fluid, true)}
        </Hidden>

        {this.renderBrush(brush1)}

        {this.renderArtwork(data.step4Picture.childImageSharp.fluid)}
        {this.renderFind(true)}
      </Fragment>
    )
  }

  renderInstall = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col>
        <Hidden xs>
          <Image
            src={number1Picture}
            alt="number 1 artwork"
            height={'136px'}
          />
        </Hidden>
        <ScreenClassRender render={screenClass => (
          <Space type={['xs', 'sm'].includes(screenClass) ? 'xxs' : 'm'} />
        )} />
        <Title text={formatMessage({ id: `home.install.steps.1.title` })} />
        <Space type="xs" />
        <Description text={formatMessage({ id: `home.install.steps.1.description` })} />
        <Space type="xs" />
        <a href={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=home-install`}>
          <ScreenClassRender render={screenClass => (
            <Button
              type="button"
              color="red"
              text={formatMessage({ id: `home.install.steps.1.action` })}
              isExpanded={['xs', 'sm'].includes(screenClass)}
            />
          )} />
        </a>
      </Col>
    )
  }

  renderConnect = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={5} offset={{ md: 1 }}>
        <Hidden xs>
          <Image
            src={number2Picture}
            alt="number 2 artwork"
            height={'136px'}
          />
        </Hidden>
        <ScreenClassRender render={screenClass => (
          <Space type={['xs', 'sm'].includes(screenClass) ? 'xxs' : 'm'} />
        )} />
        <Title text={formatMessage({ id: `home.install.steps.2.title` })} />
        <Space type="xs" />
        <Description text={formatMessage({ id: `home.install.steps.2.description` })} />
        <Space type="xs" />
        <I18nLink to={formatMessage({ id: `home.install.steps.2.uri` })}>
          <ScreenClassRender render={screenClass => (
            <Button
              type="button"
              color="outline"
              text={formatMessage({ id: `home.install.steps.2.action` })}
              iconRight={smallArrowRightBlueIcon}
              isExpanded={['xs', 'sm'].includes(screenClass)}
            />
          )} />
        </I18nLink>
      </Col>
    )
  }

  renderSearch = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col>
        <Hidden xs>
          <Image
            src={number3Picture}
            alt="number 3 artwork"
            height={'136px'}
          />
        </Hidden>
        <ScreenClassRender render={screenClass => (
          <Space type={['xs', 'sm'].includes(screenClass) ? 'xxs' : 'm'} />
        )} />
        <Title text={formatMessage({ id: `home.install.steps.3.title` })} />
        <Space type="xs" />
        <Description text={formatMessage({ id: `home.install.steps.3.description` })} />
        <Space type="xs" />
        <I18nLink to={formatMessage({ id: `home.install.steps.3.uri` })}>
          <ScreenClassRender render={screenClass => (
            <Button
              type="button"
              color="outline"
              text={formatMessage({ id: `home.install.steps.3.action` })}
              iconRight={smallArrowRightBlueIcon}
              isExpanded={['xs', 'sm'].includes(screenClass)}
            />
          )} />
        </I18nLink>
      </Col>
    )
  }

  renderFind = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={5} offset={{ md: 1 }}>
        <Hidden xs>
          <Image
            src={number4Picture}
            alt="number 4 artwork"
            height={'136px'}
          />
        </Hidden>
        <ScreenClassRender render={screenClass => (
          <Space type={['xs', 'sm'].includes(screenClass) ? 'xxs' : 'm'} />
        )} />
        <Title text={formatMessage({ id: `home.install.steps.4.title` })} />
        <Space type="xs" />
        <Description text={formatMessage({ id: `home.install.steps.4.description` })} />
        <Space type="xs" />
        <I18nLink to={formatMessage({ id: `home.install.steps.4.uri` })}>
          <ScreenClassRender render={screenClass => (
            <Button
              type="button"
              color="outline"
              text={formatMessage({ id: `home.install.steps.4.action` })}
              iconRight={smallArrowRightBlueIcon}
              isExpanded={['xs', 'sm'].includes(screenClass)}
            />
          )} />
        </I18nLink>
      </Col>
    )
  }

  renderArtwork = (src, hasMarginLeft) => {
    const offset = {
      ...(hasMarginLeft && { md: 1 }),
    }

    return (
      <Col sm={12} md={6} offset={offset}>
        <GatsbyImage
          fluid={src}
          alt="step artwork"
          style={{
            borderRadius: 12,
            maxHeight: 342,
            maxWidth: '100%',
          }}
        />
      </Col>
    )
  }

  renderBrush = (src) => {
    return (
      <Fragment>
        <Col xs={12}>
          <Space type="xl" />
        </Col>
        <Col xs={10} md={6} offset={{ xs: 1, md: 3 }}>
          <Image
            src={src}
            alt="brush artwork"
            width={'100%'}
          />
        </Col>
        <Col xs={12}>
          <Space type="xl" />
        </Col>
      </Fragment>
    )
  }
}

export default injectIntl(Install)
