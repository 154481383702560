import React from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Home/Header'
import Promises from '../scenes/Home/Promises'
import Install from '../scenes/Home/Install'
import Press from '../scenes/Home/Press'
import Feedbacks from '../scenes/Home/Feedbacks'
import Security from '../scenes/Home/Security'
import Footer from '../scenes/Footer'

import headerPicture from '../img/artworks/artwork_header_home.png'

const Home = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.home.title' })}
      metaDescription={formatMessage({ id: 'meta.home.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation
      isTransparent
      isFixed
    />
    <Header data={data} />
    <Space type="l" />
    <Promises />
    <Space type="giant" />
    <Install data={data} />
    <Space type="giant" />
    <Press />
    <Space type="giant" />
    <Security data={data} />
    <Footer />
  </Layout>
)

// This query is usable throughout the whole applicaiton
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

// This query is usable throughout the whole applicaiton
export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 192) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    headerPicture: file(relativePath: { eq: "artworks/artwork_header_home.png" }) {
      ...fluidImage
    }
    step1Picture: file(relativePath: { eq: "artworks/artwork_home_bulletPoint_1.png" }) {
      ...fluidImage
    }
    step2Picture: file(relativePath: { eq: "artworks/artwork_home_bulletPoint_2.png" }) {
      ...fluidImage
    }
    step3Picture: file(relativePath: { eq: "artworks/artwork_home_bulletPoint_3.png" }) {
      ...fluidImage
    }
    step4Picture: file(relativePath: { eq: "artworks/artwork_home_bulletPoint_4.png" }) {
      ...fluidImage
    }
    VMpicture: file(relativePath: { eq: "people/veronique_maugeri.jpeg" }) {
      ...fluidImage
    }
    CPpicture: file(relativePath: { eq: "people/christophe_plissier.jpeg" }) {
      ...fluidImage
    }
    TBpicture: file(relativePath: { eq: "people/thomas_belhamri.jpeg" }) {
      ...fluidImage
    }
    lockImage: file(relativePath: { eq: "artworks/artwork_security_lock.png" }) {
      ...fluidImage
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(Home))))
