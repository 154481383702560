import React, { Component } from 'react'
import PropTypes from 'prop-types'

const SIZE = 28

class Pill extends Component {

  render() {
    const {
      color,
    } = this.props
    const pillStyle = {
      width: SIZE,
      height: SIZE,
      background: color,
      border: '2px solid #FFFFFF',
      borderRadius: '100px',
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: -8,
      right: -8,
    }

    return (
      <div style={pillStyle} />
    )
  }
}

Pill.propTypes = {
  color: PropTypes.string.isRequired,
}

Pill.defaultProps = {
  color: undefined,
}

export default Pill
