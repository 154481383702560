import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const textClass = classNames({
  'home-install-step-title-text': true,
})

class Title extends Component {

  render() {
    const {
      text,
    } = this.props

    return (
      <h3 className={textClass}>
        {text}
      </h3>
    )
  }
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
}

Title.defaultProps = {
  text: undefined,
}

export default Title
