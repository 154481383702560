import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'

import Space from '../../../components/Space'
import Promise from '../../../components/Promise'

import activityPicture from '../../../img/icons/icon_activity.svg'
import eyePicture from '../../../img/icons/icon_eye.svg'
import searchPicture from '../../../img/icons/icon_search.svg'

const containerClass = classNames({
  'home-promises-container': true,
})

const titleContainerClass = classNames({
  'home-promises-title-container': true,
})

class Promises extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          <Row>
            {this.renderHeader()}
          </Row>
          <Space type="xxl" />
          <Row>
            {this.renderPromises()}
          </Row>
        </Container>
      </div>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'home.promises.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderPromises = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <Col sm={12} md={4}>
          <Promise
            picture={activityPicture}
            title={formatMessage({ id: 'home.promises.boost.title' })}
            description={formatMessage({ id: 'home.promises.boost.description' })}
            alt={'boost'}
          />
          <Visible xs sm>
            <Space type="m" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Promise
            picture={eyePicture}
            title={formatMessage({ id: 'home.promises.find.title' })}
            description={formatMessage({ id: 'home.promises.find.description' })}
            alt={'find'}
          />
          <Visible xs sm md>
            <Space type="m" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Promise
            picture={searchPicture}
            title={formatMessage({ id: 'home.promises.search.title' })}
            description={formatMessage({ id: 'home.promises.search.description' })}
            alt={'search'}
          />
        </Col>

      </Fragment>
    )
  }
}

export default injectIntl(Promises)
