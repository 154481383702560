import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import GatsbyImage from 'gatsby-image'

import Space from '../../../../../components/Space'
import Pill from './components/Pill'

const feedbackContainer = classNames({
  'home-feedback-container': true,
})

const pictureContainer = classNames({
  'home-feedback-picture-container': true,
})

const pictureClass = classNames({
  'home-feedback-picture': true,
})

const personTextClass = classNames({
  'home-feedback-text-person': true,
})

const quoteTextClass = classNames({
  'home-feedback-text-quote': true,
})

class Feedback extends Component {

  render() {
    const {
      name,
      position,
      quote,
    } = this.props

    return (
      <div className={feedbackContainer}>
        {this.renderPicture()}
        <Space type="xs" />
        <p className={personTextClass}>
          {name}
        </p>
        <p className={personTextClass}>
          {position}
        </p>
        <Space type="s" />
        <p className={quoteTextClass}>
          {quote}
        </p>
      </div>
    )
  }

  renderPicture = () => {
    const {
      picture,
      color,
      name,
    } = this.props

    return (
      <div className={pictureContainer}>
        <GatsbyImage
          className={pictureClass}
          fluid={picture}
          alt={name}
        />
        <Pill
          color={color}
        />
      </div>
    )
  }
}

Feedback.propTypes = {
  picture: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

Feedback.defaultProps = {
  picture: undefined,
  color: undefined,
  name: undefined,
  position: undefined,
  quote: undefined,
}

export default Feedback
