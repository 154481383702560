import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Hidden, ScreenClassRender, Visible } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Image from '../../../components/Image'
import Inline from '../../../components/Inline'
import Space from '../../../components/Space'

import iconSmallArrowRightBlue from '../../../img/icons/icon_small_arrow_right_blue.svg'
import ansiImage from '../../../img/logos/security_anssi.png'
import iso27001Image from '../../../img/logos/security_iso_27001.png'
import soc2Image from '../../../img/logos/security_soc_2.png'
import thalesImage from '../../../img/logos/security_thales.png'

const backgroundClass = classNames({
  'home-security-background': true,
})

const imageContainer = classNames({
  'home-security-image-container': true,
})

const contentContainerClass = classNames({
  'home-security-content-container': true,
})

const moreContainer = classNames({
  'home-security-content-more-container': true,
})

const logosContainer = classNames({
  'home-security-content-logos-container': true,
})

class Security extends Component {

  render() {
    return (
      <div className={backgroundClass}>
        <Container>
          <Space type="l" />
          <Row>
            {this.renderLeftPane()}
            {this.renderRightPane()}
          </Row>
          <Space type="xl" />
        </Container>
      </div>
    )
  }

  renderLeftPane = () => {
    const {
      data,
    } = this.props

    return (
      <Col sm={12} md={4}>
        <div className={imageContainer}>
          <ScreenClassRender render={screenClass => (
            <GatsbyImage
              fluid={data.lockImage.childImageSharp.fluid}
              alt="lock"
              style={{
                width: ['xs', 'sm'].includes(screenClass) ? '128px' : '256px',
                height: ['xs', 'sm'].includes(screenClass) ? '128px' : '256px',
              }}
            />
          )} />

          <Visible xs sm>
            <Space type="m" />
          </Visible>
        </div>
      </Col>
    )
  }

  renderRightPane = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={7}>
        <div className={contentContainerClass}>
          <h2>
            {formatMessage({ id: 'home.security.title' })}
          </h2>
          <Space type="xl" />

          <h3>
            {formatMessage({ id: 'home.security.privacy.title' })}
          </h3>
          <Space type="xs" />
          <p>
            {formatMessage({ id: 'home.security.privacy.description' })}
          </p>
          <Space type="m" />

          <h3>
            {formatMessage({ id: 'home.security.security.title' })}
          </h3>
          <Space type="xs" />
          <p>
            {formatMessage({ id: 'home.security.security.description' })}
          </p>
          <Space type="l" />

          {this.renderMore()}
        </div>
      </Col>
    )
  }

  renderMore = () => {
    return (
      <div className={moreContainer}>
        {this.renderButton()}
        <Visible xs sm>
          <Space type="m" />
        </Visible>
        <Hidden xs sm>
          <Inline type="m" />
        </Hidden>
        {this.renderLogos()}
      </div>
    )
  }

  renderButton = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <I18nLink to={formatMessage({ id: 'home.security.action.uri' })}>
        <ScreenClassRender render={screenClass => (
          <Button
            type="button"
            color="outline"
            text={formatMessage({ id: 'home.security.action.readMore' })}
            iconRight={iconSmallArrowRightBlue}
            isExpanded={['xs', 'sm'].includes(screenClass)}
          />
        )} />
      </I18nLink>
    )
  }

  renderLogos = () => {
    return (
      <div className={logosContainer}>
        <div>
          <Image
            src={ansiImage}
            alt="ansi"
            height={48}
          />
        </div>
        <Visible xs sm>
          <Inline type="l" />
        </Visible>
        <div>
          <Image
            src={iso27001Image}
            alt="iso27001"
            height={48}
          />
        </div>

        <Visible xs sm>
          <Space type="xs" />
        </Visible>

        <div>
          <Image
            src={soc2Image}
            alt="soc2"
            height={48}
          />
        </div>
        <Visible xs sm>
          <Inline type="l" />
        </Visible>
        <div>
          <Image
            src={thalesImage}
            alt="thales"
            height={16}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(Security)
