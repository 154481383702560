import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system'

import Image from '../../../components/Image'
import Space from '../../../components/Space'
import Feedback from './components/Feedback'

import brushGreenPicture from '../../../img/artworks/artwork_home_brush_green.svg'

const containerClass = classNames({
  'home-feedbacks-container': true,
})

const titleContainerClass = classNames({
  'home-feedbacks-title-container': true,
})

class Feedbacks extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          <Row>
            {this.renderHeader()}
          </Row>
          <Space type="giant" />
          <Row>
            {this.renderFeedbacks()}
          </Row>
        </Container>
      </div>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'home.feedbacks.title' })}
          </h2>
          {this.renderArtefacts()}
        </div>
      </Col>
    )
  }

  renderArtefacts = () => {
    return (
      <Hidden xs sm md>
        <Image
          src={brushGreenPicture}
          alt="brush"
          style={{
            zIndex: -1,
            right: 256,
            top: -8,
            position: 'absolute',
          }}
        />
      </Hidden>
    )
  }

  renderFeedbacks = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <Col sm={12} md={4}>
          <Feedback
            picture={data.VMpicture.childImageSharp.fluid}
            color={'#1E1D53'}
            name={formatMessage({ id: 'home.feedbacks.feedback.vm.name' })}
            position={formatMessage({ id: 'home.feedbacks.feedback.vm.position' })}
            quote={formatMessage({ id: 'home.feedbacks.feedback.vm.quote' })}
          />
          <Visible xs sm>
            <Space type="s" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Feedback
            picture={data.CPpicture.childImageSharp.fluid}
            color={'#3886C7'}
            name={formatMessage({ id: 'home.feedbacks.feedback.cp.name' })}
            position={formatMessage({ id: 'home.feedbacks.feedback.cp.position' })}
            quote={formatMessage({ id: 'home.feedbacks.feedback.cp.quote' })}
          />
          <Visible xs sm>
            <Space type="s" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Feedback
            picture={data.TBpicture.childImageSharp.fluid}
            color={'#1CE872'}
            name={formatMessage({ id: 'home.feedbacks.feedback.tb.name' })}
            position={formatMessage({ id: 'home.feedbacks.feedback.tb.position' })}
            quote={formatMessage({ id: 'home.feedbacks.feedback.tb.quote' })}
          />
        </Col>
      </Fragment>
    )
  }
}

export default injectIntl(Feedbacks)
